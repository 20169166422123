/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~material-design-icons/iconfont/material-icons.css';
@import '~highlight.js/styles/googlecode.css';

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.position-static {
  position: static;
}

mat-form-field.mat-form-field-appearance-outline {
  div.mat-form-field-outline,
  div.mat-form-field-outline.mat-form-field-outline-thick {
    color: var(--gray);
    opacity: 0.2;
  }
}

div.mat-form-field-flex div.mat-form-field-infix {
  padding: 0.8em 0 0.8em 0;
}

form.job-form {
  cjp-date-field .mat-form-field-infix {
    display: flex;
    padding: 0;
  }
}

.center-action {
  .mat-simple-snackbar {
    justify-content: center;
    color: var(--success);
    font-weight: bold;
  }
}

.center-action--failed {
  .mat-simple-snackbar {
    justify-content: center;
    color: var(--danger);
    font-weight: bold;
  }
}

html[theme='default'] {
  --blue: #35a3f7;
  --info: #35a3f7;
  --primary: #35a3f7;
  --danger: #fd397a;
  --danger-hex: 253, 57, 122;
  --purple: #646c9a;
  --purple-hex: 100, 108, 154;
  --gray-hex: 108, 117, 125;
  --font-regular: Poppins, Helvetica, sans-serif;
  --font-heading: Poppins, Helvetica, sans-serif;
  --mobile-width: 500px;
}

mat-bar-button.login-button {
  button.mat-button.mat-raised-button {
    width: 100%;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
  }
}

input[readonly].form-control {
  background-color: var(--light);
}
